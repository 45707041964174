import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { AppUser, AppUserDoc, AppUsersDict } from "../types/users";
import db, { functions } from "../types/firebase";
import { httpsCallable } from "firebase/functions";

interface CreateUserResult {
  data: {
    status: string;
    error?: string;
    id?: string;
    user?: AppUser;
  };
}

interface SendCredentialsResult {
  data: {
    status: string;
    error?: string;
  };
}

export const loadAppUser = async (userId: string): Promise<AppUser | null> => {
  if (!userId) return null;
  const userQuery = query(collection(db, "users"), where("id", "==", userId));
  const userSnapshot = await getDocs(userQuery);
  if (!userSnapshot.empty) {
    return userSnapshot.docs[0].data() as AppUser;
  } else {
    return null;
  }
};

export const loadAppUsers = async (user: AppUser | null | undefined) => {
  if (!user) {
    return {};
  }
  const sponsorId = user && user.sponsorId ? user.sponsorId : "";

  let usersRef;
  let usersSnapshot;
  const usersDict: AppUsersDict = {};

  // If the user is not an admin, return only the user's own data
  if (!user.role.includes("admin")) {
    usersRef = query(collection(db, "users"), where("id", "==", user.id));
  } else if (sponsorId) {
    // Load only the users for the sponsor
    usersRef = query(collection(db, "users"), where("sponsorId", "==", sponsorId));
  } else {
    // super admin --> Load all users
    usersRef = collection(db, "users");
  }

  usersSnapshot = await getDocs(usersRef);
  usersSnapshot.forEach((doc) => {
    const appUser = doc.data() as AppUser;
    usersDict[doc.id] = appUser;
  });
  return usersDict;
};

export const existsAppUserEMail = async (email: string) => {
  console.log("Checking for user with email: " + email);
  const q = query(collection(db, "users"), where("email", "==", email));
  const querySnapshot = await getDocs(q);
  console.log("email exists: " + !querySnapshot.empty);
  return !querySnapshot.empty;
};

export const storeAppUser = async (
  user: AppUser | null | undefined,
  userDoc: AppUserDoc | null,
  addIfNew: boolean
) => {
  if (!(userDoc && userDoc.user)) {
    return null;
  }

  let userSnapshot = null;

  userDoc.user.email = userDoc.user.email.toLowerCase();

  if (userDoc.id) {
    const docRef = doc(db, "users", userDoc.id);
    userSnapshot = await getDoc(docRef);
    // Update the document
    if (userSnapshot) {
      userDoc.user.updatedAt = new Date().toISOString();
      userDoc.user.updatedBy = user ? user.id : "anonymous";
      await setDoc(docRef, userDoc.user);
      console.log("updated user:", userDoc.id);
    }
  } else if (addIfNew) {
    userDoc.user.createdAt = new Date().toISOString();
    userDoc.user.createdBy = user ? user.id : "anonymous";
    userDoc.id = (await addDoc(collection(db, "users"), userDoc.user)).id;
    console.log("created user:", userDoc.id);
  } else {
    return null;
  }
  return { ...userDoc };
};

export const disableUser = async (
  user: AppUser | null | undefined,
  userId: string
): Promise<string> => {
  if (!userId) return "no-user";
  if (!user || !user.role.includes("admin")) {
    return "insufficient-permissions";
  }

  const fncDisableUser = httpsCallable(functions, "api/users/disableUser");

  try {
    const response = (await fncDisableUser({ userId })) as CreateUserResult;
    console.log("delete user response: ", response);
    if (response.data.status === "OK") {
      return "success";
    } else return "error";
  } catch (err) {
    return "error";
  }
};

export const enableUser = async (
  user: AppUser | null | undefined,
  userId: string
): Promise<string> => {
  if (!userId) return "no-user";
  if (!user || !user.role.includes("admin")) {
    return "insufficient-permissions";
  }

  const fncEnableUser = httpsCallable(functions, "api/users/enableUser");

  try {
    const response = (await fncEnableUser({ userId })) as CreateUserResult;
    console.log("delete user response: ", response);
    if (response.data.status === "OK") {
      return "success";
    } else return "error";
  } catch (err) {
    return "error";
  }
};

export const createUser = async (user: AppUser, password: string) => {
  console.log("creating new user: ", user);
  const fncCreateUser = httpsCallable(functions, "api/users");
  try {
    const response = (await fncCreateUser({ user: user, password: password })) as CreateUserResult;
    console.log("createUser response: ", response);
    console.log("response: ", response);
    if (response.data.status === "OK") {
      return { status: "OK", id: response.data.id, user: response.data.user };
    } else return { status: "ERROR", error: response.data.error };
  } catch (err) {
    return { status: "ERROR", error: err };
  }
};

export const sendLoginCredentials = async (user: AppUser, password: string) => {
  console.log("user: ", user);

  const fncSendLoginCredentials = httpsCallable(functions, "api/users/sendLoginCredentials");
  try {
    const response = (await fncSendLoginCredentials({
      userId: user.id,
      password: password,
    })) as SendCredentialsResult;
    console.log("sendLoginCredentials response: ", response);
    return response.data;
  } catch (err) {
    return { status: "ERROR", error: err };
  }
};
