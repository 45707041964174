import { createTheme } from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";
//import { bgBG } from "@mui/material/locale";

export const headerColor = blue[900];
export const ibcMainBlue = "#153871";
const dialogBackgroundMainColor = "#C0EEFA";
// const dialogBorderColor = "#28D2F9";
//const dialogBackgroundMainColor = "#86ADEC";
//const secondaryColor = "#328D2F9";

const theme = createTheme({
  typography: {
    fontFamily: "sans-serif",
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            fontWeight: "normal",
          },
          "& .MuiTableCell-head": {
            backgroundColor: headerColor,
            color: "white",
            fontWeight: "bold",
            fontSize: "1rem",
            lineHeight: "1.334rem", // because of the Card headers
            "& .MuiTableSortLabel-root": {
              backgroundColor: headerColor,
              color: "white",
              fontWeight: "bold",
              fontSize: "1rem",
              "& .MuiTableSortLabel-icon": {
                color: "white",
              },
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.selected": {
            backgroundColor: blue[100],
          },
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderWidth: "0px",
          "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
              borderTop: "1px solid",
              borderTopColor: "#e0e0e0",
              borderBottom: "0px",
            },
          },
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: headerColor,
          color: "white",

          "& .MuiTypography-root": {
            fontWeight: "bold",
            fontSize: "1rem",
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          marginTop: "8px",
          marginBottom: "4px",
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          color: blue[700],
          borderColor: blue[700],
          "&::before": {
            width: "2%",
            borderColor: blue[700],
          },
          "&::after": {
            borderColor: blue[700],
          },
        },
        light: {
          color: grey[400],
          borderColor: grey[400],
          "&::before": {
            width: "2%",
            borderColor: grey[400],
          },
          "&::after": {
            borderColor: grey[400],
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: blue[700],
          "&.Mui-checked": {
            color: blue[700],
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: ibcMainBlue,
          "& .MuiTextField-root": {
            "& .MuiSvgIcon-root": {
              color: "white",
            },
            "& .MuiInputBase-root": {
              color: "white",
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "white",
              },
              "&.Mui-focused fieldset": {
                borderColor: "white",
              },
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.language-switcher": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            "&:hover": {
              borderColor: "rgba(0, 0, 0, 0.87)",
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: `linear-gradient(to bottom, ${dialogBackgroundMainColor} 0%, white 55%)`,
          borderRadius: "15px",
          border: `3px solid ${ibcMainBlue}`,
          "&.MuiDialog-paperFullScreen": {
            borderRadius: "0px",
            border: "none",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: ibcMainBlue,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: ibcMainBlue,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: "flex",
          justifyContent: "space-between",
        },
      },
    },
  },
});

export default theme;
