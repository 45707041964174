import { useContext, useEffect, useState } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";

import { useParams } from "react-router-dom";

import { isLanguageSupported, messages, SupportedLanguages } from "../../languages/languages";
import { Typography, Container, Card, Box, Button } from "@mui/material";
import { ApplicantDoc } from "../../types/applicants";
import { ApplicantDetails } from "./applicant-data";
import { PageInvalidQuestionnaire } from "./pageInvalidQuestionnaire";
import { isDateInThePast, isEMailValid } from "../../utils/utils";
import { ApplicationDoc } from "../../types/visa-application";
import { loadApplications } from "../../data-functions/applications-api";
import { findApplicantByIdAndEmail, loadApplicant } from "../../data-functions/applicants-api";
import { AuthContext } from "../../components/auth-provider";
import { CustomTextField } from "../../components/custom-textfield";

type ParamTypes = {
  qid: string;
};

type FormState = "loading" | "verify" | "done" | "expired" | "badID";

export const Questionnaire = () => {
  const { qid } = useParams<ParamTypes>();
  const [formState, setFormState] = useState<FormState>("loading");
  const [applicantDoc, setApplicant] = useState<ApplicantDoc | null>(null);
  const [applicationDoc, setApplication] = useState<ApplicationDoc | null>(null);
  const [candidateEmail, setCandidateEmail] = useState<string>("");
  const [language, setLanguage] = useState<SupportedLanguages>("en");
  const [isUserApplicant, setIsApplicant] = useState<boolean>(true);

  const [emailError, setEmailError] = useState<string>("");

  const { currentUser } = useContext(AuthContext)!;

  useEffect(() => {
    const fetchData = async () => {
      console.log("qid: " + qid);
      if (!qid) {
        setFormState("badID");
        //setMessage("You need a valid link for your questionnaire");
        return;
      }

      const applications = await loadApplications(null, { questionnaireId: qid });

      if (!applications || Object.keys(applications).length === 0) {
        console.log("no document with this id found");
        setFormState("badID");
        //        setMessage("You need a valid link for your questionnaire");
        return;
      }

      const id = Object.keys(applications)[0];
      const applDoc: ApplicationDoc = {
        id,
        application: applications[id],
      };

      const isApplicant =
        !currentUser || !currentUser.appUser || currentUser.appUser.role.includes("applicant");
      setIsApplicant(isApplicant);

      if (applDoc.application.dataForm_expiry_date) {
        console.log(
          "date in the past: " +
            isDateInThePast(applDoc.application.dataForm_expiry_date.toString())
        );
      }

      if (
        applDoc.application &&
        applDoc.application.dataForm_expiry_date &&
        isDateInThePast(applDoc.application.dataForm_expiry_date.toString()) &&
        isApplicant
      ) {
        console.log("expired");
        setFormState("expired");
        return;
      }

      if (!isApplicant) {
        const userId = applDoc.application.applicantId;
        console.log("applicant: " + userId);

        const applicantData = await loadApplicant(userId);
        console.log("Applicant data:");
        console.log(applicantData);

        if (applicantData) {
          console.log("setting applicant: " + applicantData.id);
          setApplication(applDoc);
          setApplicant(applicantData);
          if (applicantData.applicant.preferredLanguage) {
            setLanguage(applicantData.applicant.preferredLanguage as SupportedLanguages);
          }
          setFormState("done");
        } else {
          setFormState("badID");
        }
      } else {
        setApplication(applDoc);
        setFormState("verify");
      }
    };

    const lng = navigator.language.split(/[-_]/)[0];
    console.log("Browser language: " + lng);
    if (isLanguageSupported(lng)) {
      setLanguage(lng as SupportedLanguages);
    }
    if (currentUser === null) {
      return;
    }
    fetchData();
  }, [qid, currentUser]);

  const handleLanguageChange = (language: SupportedLanguages) => {
    console.log("Language: " + language);
    setLanguage(language);
  };

  const handleSubmit = async () => {
    console.log("candidateEmail: " + candidateEmail);
    console.log("isEMailValid: " + isEMailValid(candidateEmail.trim().toLocaleLowerCase()));

    if (!candidateEmail || !isEMailValid(candidateEmail.trim().toLocaleLowerCase())) {
      setEmailError("Please enter a valid email address");
      return;
    }

    const email = candidateEmail.trim().toLocaleLowerCase();

    console.log("applicationDoc: ", applicationDoc);
    console.log("applicationDoc.application: ", applicationDoc?.application);

    if (!(applicationDoc && applicationDoc.application)) {
      setFormState("badID");
      return;
    }

    const userId = applicationDoc.application.applicantId;
    console.log("applicant: " + userId);

    const applicantData = await findApplicantByIdAndEmail(userId, email);
    console.log("Applicant data:");
    console.log(applicantData);

    if (applicantData) {
      console.log("setting applicant: " + applicantData.id);
      setApplicant(applicantData);
      if (applicantData.applicant.preferredLanguage) {
        setLanguage(applicantData.applicant.preferredLanguage as SupportedLanguages);
      }
      setFormState("done");
    } else {
      setFormState("badID");
    }
  };

  const getVerifyEmailForm = () => {
    return (
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Box
          sx={{
            padding: {
              xs: "0",
              md: "3rem",
            },
            paddingTop: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.25rem", md: "1.5rem" },
              fontWeight: "bold",
              marginBottom: "1rem",
              marginTop: { xs: "1rem", md: "0" },
            }}
          >
            <FormattedMessage
              id="welcome_title"
              defaultMessage="Working in the United Kingdom with a visa"
            />
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            <FormattedMessage
              id="welcome_verify_email"
              defaultMessage="Please, provide your email address in order to continue with the questionnaire."
            />
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
            <CustomTextField
              name="email"
              label="Email"
              error={!!emailError}
              helperText={emailError}
              autoFocus
              type="text"
              value={candidateEmail}
              onChange={(e) => setCandidateEmail(e.target.value)}
              required
              fullWidth
              sx={{ marginBottom: "1rem" }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" onClick={handleSubmit}>
              <FormattedMessage id="button_continue" defaultMessage="Continue" />
            </Button>
          </Box>
        </Box>
      </Container>
    );
  };

  const getForm = () => {
    switch (formState) {
      case "loading":
        return (
          <Typography variant="h5" color="error">
            Loading form data...
          </Typography>
        );

      case "badID":
        return <PageInvalidQuestionnaire formState="badID" />;

      case "expired":
        return <PageInvalidQuestionnaire formState="expired" />;

      case "verify":
        return getVerifyEmailForm();
      default:
        break;
    }
  };

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Card sx={{ marginTop: "1rem", padding: { xs: "0", md: "1rem" } }}>
          {formState !== "done" && getForm()}
          {applicantDoc && applicationDoc && (
            <ApplicantDetails
              isApplicant={isUserApplicant}
              applicant={applicantDoc}
              application={applicationDoc}
              defaultLanguage={language}
              changeLanguage={handleLanguageChange}
            />
          )}
        </Card>
      </Container>
    </IntlProvider>
  );
};
